.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  font-family: 'Rubik', sans-serif;
  position: absolute;
  top: 0;
  margin: auto;
  width: 100%;
}
header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 500px;
  max-width: 80vw;
  flex: 1;
}
#projectDescription {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 1em;
}
#logo {
  width: 300px;
}

#buttonCreator {
  border-radius: 10px;
  background: white;
  padding: 10px;
}

.filePicker {
  visibility: hidden;
  position: absolute;
  z-index: -1;
}
.magicShadow {
  filter: drop-shadow(0px 4px 4px rgba(0,0,0,0.25));
}
.magicRadius {
  border-radius: 10px;
}

#editor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: white;
  padding: 1em;
  border-radius: 10px;
}
#editorButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
#newButton {
  padding: 64px;
  width: 500px;
  max-width: 80vw;
}

.pulsing {
  animation: pulse 3s infinite;
}

#pin3d {
  height: 300px !important;
}

#selectCount {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

#selectCount > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
#editor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.image-container {
  position: absolute;
  display: inline-block;
  z-index: -1;
  top: -100vh;
}

.overlay-image {
  position: absolute;
  bottom: -55px;
  left: -55px;
  clip-path: circle(50%);
}

#rendered-image {
  display: none;
}

#hiddenCompose {
  visibility: hidden;
}

#canvas-container {
  position: relative;
}

#avatar-editor {
  width: 300px !important;
  height: 300px !important;
}
#avatar-inner-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
#avatar-inner-circle::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 177px;
  height: 177px;
  border: 5px dashed white;
  border-radius: 50%;
}
#controls {}

.closePicker {
  position: absolute;
  top: 1em;
  left: 1em;
}
