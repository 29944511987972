@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: beige;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
